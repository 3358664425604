/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    br: "br",
    ul: "ul",
    li: "li",
    em: "em",
    strong: "strong"
  }, _provideComponents(), props.components), {TableOfContents, SideBySide, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "experience-oticon-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#experience-oticon-hearing-aids",
    "aria-label": "experience oticon hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Experience Oticon Hearing Aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "As one of our five senses, hearing is critical to being able to communicate effectively with others. With a diminished capacity for hearing, we miss out on so much of our daily life and interactions, which is why hearing loss can be so difficult to come to terms with. Thankfully, there are solutions and support."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "introducing-life-changing-technology",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#introducing-life-changing-technology",
    "aria-label": "introducing life changing technology permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Introducing Life-Changing Technology"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/brands-oticon.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Oticon is built on a heritage and purpose of People First to reinforce the power of technology to improve not only the quality of hearing, but the overall quality of life. For more than 115 years, Oticon has brought life-changing ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aid devices"), " to market that exceeds the needs and expectations of people with hearing loss.\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "Liberating people from the limitations of hearing loss requires insight into how people live their lives and how the brain works. The ", React.createElement(_components.a, {
    href: "/hearing-aids/brands/",
    className: "c-md-a"
  }, "hearing aid brand"), ", Oticon, never stops exploring and learning about the people who live with it and the role of the brain in hearing loss and well-being.")), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "hearing-with-oticon",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-with-oticon",
    "aria-label": "hearing with oticon permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing with Oticon"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "An Oticon hearing aid goes beyond just better hearing. Oticon state-of-the-art hearing solutions are designed with no limitations and are customized to meet your individual needs — and boost your overall well-being."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Oticon features six impressive product lines:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "More™ Premium miniRITE R styles"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Opn S™ Premium BTE & RITE styles"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Opn™ Custom ITE styles"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Siya Custom ITE styles"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Ruby BTE miniRITEs"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Xceed Power BTEs styles"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Each line has its own unique advantages and features."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "oticon-more-premium-minirite-r-styles",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#oticon-more-premium-minirite-r-styles",
    "aria-label": "oticon more premium minirite r styles permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Oticon More™ Premium miniRITE R styles"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/oticon-in-hand.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Oticon More hearing aids have built-in intelligence to help you make more sense of sound. It’s the world’s first hearing aid with an on-board Deep Neural Network designed to support your brain. It allows for effortless connections and direct streaming from your iPhone, iPad and Android devices.\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "The new Oticon More™ miniRITE R is a discreet, lithium–ion-based rechargeable hearing aid that provides a full day of battery life,* including streaming, after just three hours of charging. These hearing aids will give you better hearing and speech understanding in a variety of environments, with less effort and the ability to remember more.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "*", React.createElement(_components.em, null, "Lithium-ion performance varies depending on hearing loss, lifestyle and streaming behavior.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "oticon-opn-s-premium-btes-and-rites",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#oticon-opn-s-premium-btes-and-rites",
    "aria-label": "oticon opn s premium btes and rites permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Oticon Opn S™ Premium BTEs and RITEs"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/oticon-opn-s-premium.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Oticon Opn S™ hearing aids are designed to help more people with hearing loss experience high-quality sound with fewer restrictions. The powerful ", React.createElement(_components.strong, null, "Behind-the-Ear (BTE) and Receiver-in-the-Ear (RITE) styles"), " all boast cutting-edge features while allowing for person-specific comfort.\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "Each of these styles is packed full of the same great Opn S™ technology, which is proven to help users experience speech understanding at the same level as people with normal hearing. From the 360-degree sound that opens up your world to OpenSound Optimizer technology that detects and prevents annoying whistling before it happens, the Opn S™ platform offers users a freedom like never before.")), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "opn-custom-ite-styles",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#opn-custom-ite-styles",
    "aria-label": "opn custom ite styles permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Opn™ Custom ITE Styles"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/oticon-opn-hearing-aids-range.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Oticon Opn™ Custom In-the-Ear (ITE) hearing aids are expertly crafted to fit your unique needs and preferences. Available in five colors and styles with a wide range of sound processing features and conveniences, including wireless connectivity in Half Shell and Full Shell styles.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Rather than filtering out some sounds and leaving others behind, this hearing aid works with and facilitates the brain’s normal modes by allowing it to follow and process several sound sources all at the same time. Taking an incredible leap forward, the Velox technology platform delivers unparalleled speed — 50 times faster than its closest competitor — to deliver a more sophisticated sound landscape, opening up sound all around you."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "xceed-power-btes",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#xceed-power-btes",
    "aria-label": "xceed power btes permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Xceed Power BTEs"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Oticon has unveiled the world’s most powerful solution for those struggling with severe hearing loss. If you’ve suffered through reading lips and are tired of guessing what your friends, family, and co-workers are saying, then the ", React.createElement(_components.strong, null, "Xceed Behind-the-Ear (BTE) hearing aid"), " for severe-to-profound loss is for you. Featuring Proven BrainHearing™ technology that scans your environment 100 times per second, the Xceed provides better speech clarity than ever before and requires less listening effort on your part — leaving you with more energy for other activities. Like many of Oticon’s other hearing aids, you’ll experience the open-world around you with 360-degree sound, less feedback and whistling with Oticon’s OpenSound Optimizer™ technology, and of course wireless connectivity to any smartphone. The Xceed is available in two styles and seven colors to better meet your individual needs."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "oticon-ruby-bte-minirites",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#oticon-ruby-bte-minirites",
    "aria-label": "oticon ruby bte minirites permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Oticon Ruby BTE miniRITEs"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/oticon-ruby-with-charger.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Oticon Ruby is the latest in an essential line of hearing aids that offers excellent sound quality, Bluetooth wireless connectivity and state-of-the-art lithium-ion rechargeability. The charger provides reliable inductive charging with a magnetic connection that ensures the hearing aids are easily inserted and remain in the charger. Ruby is truly ideal for anyone who desires convenience and easy-to-use features at a cost-conscious price. BrainHearingTM technology continues to deliver uncompromised sound quality to the brain to provide an excellent listening experience. And the new SuperShield feature stops feedback and whistling before it starts. Ruby is available in five behind-the-ear styles, five colors, and two performance levels powered by the ultra-fast Velox S™ platform for excellent sound processing.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "oticon-siya",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#oticon-siya",
    "aria-label": "oticon siya permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Oticon Siya"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/oticon-siya.png"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Oticon Siya is well known for helping everyday people hear better in daily conversations. Designed specifically for superior sound quality and improved speech understanding, Siya hearing aids provide effective noise reduction to deliver a superior user experience in a cost-effective device.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Siya is available in two performance levels of RITE and BTE styles. Siya allows a wide range of seamless connections with a variety of devices, like your smartphone or TV thanks to its ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth",
    className: "c-md-a"
  }, "Bluetooth capabilities"), ". Siya can be controlled directly via a smartphone app to stream high-quality sound straight to your ears. What could be better?"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "accessories",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#accessories",
    "aria-label": "accessories permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Accessories"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Impressive accessories from Oticon are designed to work in partnership with your hearing aids to further enhance your hearing and hearing aid capabilities."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "connectclip",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#connectclip",
    "aria-label": "connectclip permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "ConnectClip"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you want all the modern conveniences at your fingertips, the ConnectClip is a must-have tool for your toolkit. This small, simple device simply clips onto your clothing and transforms your hearing aids into a modern wireless headset. You’ll be able to make and take hands-free phone calls from any smartphone; hear speakers at a distance through the remote microphone function — which also is useful during any challenging listening situation; stream video chats from your computer directly into your ears; and even use the Bluetooth microphone to make video calls. There’s even a remote control function that allows you to adjust your hearing aids without anyone noticing."), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/oticon-opn-s-rechargeable.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "rechargeable-kit",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#rechargeable-kit",
    "aria-label": "rechargeable kit permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Rechargeable Kit"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "The simple-to-use hearing aid charger is a game-changer if you’re wearing one of the newest rechargeable hearing aids by Oticon. Simply set the device near your bed to remind yourself each night to charge your hearing aids while you sleep. You don’t even have to remember to turn your hearing aids off when you insert them into the charger. Your hearing aids will charge in just three hours and will last all day! No more changing batteries and a quick charge time? It doesn’t get better than that.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "oticon-hearing-foundation",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#oticon-hearing-foundation",
    "aria-label": "oticon hearing foundation permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Oticon Hearing Foundation"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Besides their constant stream of innovation in the world of hearing healthcare, Oticon is dedicated to philanthropic efforts in their industry through the Oticon Hearing Foundation. The Foundation’s goal is to improve individual and community access to hearing healthcare and technology around the world."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Through their foundation, Oticon has partnered with a number of other organizations in recent months to provide tens of thousands of dollars’ worth of university scholarships for students with hearing loss. Additionally, the Oticon Hearing Foundation recently made a sizeable donation of hearing aid devices to students in various communities that wouldn’t otherwise have access to such quality technology."), "\n", React.createElement(LandingPageCta, {
    copy: "Get Oticon hearing aids now",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
